

.Hydro-Page{
    background: url("../../public/images/Icon.png") center, radial-gradient(red 30%,#B0000F);
    background-size: contain;
    background-repeat: no-repeat;
    }
    
    .BottomBar{
        background-color: #B0000F;
    }