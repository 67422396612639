

*{
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.main{
   
    width: 100vw;
    height: 100vh;
    background: url("../../public/images/Icon.png") center, radial-gradient(red 5%,#B0000F);
background-size: contain;
background-repeat: no-repeat;

}

video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.Wallpaper{
    object-fit: cover;
}

.Content{
    position: absolute;
    height: 88%;
    top: 1%;
    left: 4%;
    justify-content: space-between;
    text-align: center;
}

.overlay2{

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, .1);
}

.Container1{
    margin-top: 5rem;
    padding: 5rem;
}

.Container1 .Heading {
    color: white;
    text-align: center;
    font-family: 'Merriweather', serif; 
}



