 
 
 .HydroPlant{ width: 92%;
max-width: 1200px;
object-fit: cover;
position: absolute;
transform: rotate(-8deg);
z-index: 1;
 }

 


.GMS{
    position: absolute;
    z-index: 10;
    width: 85%;
    margin-top: 10%;
}
.Typo1 , .Typo2 , .Typo3 , .Typo4 ,.Typo5 ,.Bypassvalve ,.GMSingapore ,.left-arrow,.DriveTest1, .DriveTest2{
    position: absolute;
    z-index: 3;
    
}

.DriveTest1{
    height: 13%;
    margin-top: 7%;
    margin-left: 25%;
}

.DriveTest2{
    height: 11.3%;
    margin-left: 71%;
    margin-top: -4%;
}
.Typo1 {
    margin-top: 23%;
    margin-left: 5%;
}
.Typo2  {
margin-top: 44%;
margin-left: 15%;
}
.Typo3 {
    margin-top: 39%;
    
}
.Typo4 {
  margin-top: 24%;
  margin-left: 19%;
}
.Typo5 {
    margin-top: 12%;
    margin-left: 65%;
    
}
.left-arrow{
    margin-top: 33%;
    margin-left: 19%;
    transform: rotate(8deg);
}
.Bypassvalve{
    margin-top: 38%;
    margin-left: 30%;

}
.PIBV{
    position: absolute;
    z-index: 3;
    width: 90px;
    margin-top: 36%;
    margin-left: 70%;
}

/* lg Screen */
 
.HydroPlant2{ width: 72%;
    object-fit: contain;
    position: absolute;
    z-index: 0;
    margin-left: 16%;
    margin-top: -11%;
   transform: rotate(-2deg);
     }

     .GMSingapore2{
        position: absolute;
        z-index: 2;
    }  
     .Typo6 , .Typo7 , .Typo8 ,.Bypassvalve2 ,.Typo9 ,.Typo10 , .left-arrow1 , .LeftRpm ,.RightRpm,.DriveTest4, .DriveTest3{
        position: absolute;
        z-index: 2;
        
    }
  .DriveTest3,.DriveTest4 {
    height: 200%;
    margin-top: -120.6%;
    margin-left: -113.2%;
  }
 

    .GMS2{
        position: absolute;
        z-index: 10;
        width: 50%;
        margin-top: -5%;
       margin-left: 20%;
    }
    
 .PIBV2{
     position: absolute;
     z-index: 5;
     margin-top: 10%;
 }

    .Typo6{
      margin-top: 5%;
      margin-left: 11%;
    }
    .Typo7{
        margin-top: 11%;
        margin-left: 13%;
        
    }
    .Typo8{
        margin-top: 8%;
        margin-left: 15%;
    }
    .LeftRpm{
        margin-top: -3%;
        margin-left: 32%;
    }
    .RightRpm{

        margin-left: 70%;
        margin-top: -7.5%;
    }

    .Bypassvalve2{

        margin-top: 3%;
        margin-left: 28%;
      
    }
    /* .left-arrow1{
        margin-top: 17%;
        margin-left: 24%;
        transform: rotate(20deg);
    } */