

h4{
    font-family: 'Merriweather', serif; 
    color: white;
    margin-left: 10%;
    margin-top: 10%;
    font-weight: 400;
    font-size: calc(0.7rem + 0.3vw);
    }



.headingStyle{
    font-family: 'Merriweather', serif; 
    text-align: center;
    color: white;
    font-size: calc(1rem + 0.2vw);
    margin-top: 1%;
    margin-bottom: 1%;
}



.topicTypo{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.8rem + 0.1vw);
    font-weight: 400;
    color: white;
}



h3{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.8rem + 0.1vw);
    color: white;
    font-weight: 400;
    margin-bottom: 0.2rem;

}




h5{
    font-family: 'Merriweather', serif; 
    font-size: calc(0.75rem + 0.1vw);
    color: white;
    font-weight: 400;
    margin-bottom: 0.2rem;
}




h6{
    font-family: 'Merriweather', serif; 
    text-align: center;
    margin-top: 8px;
    font-size: calc(0.78rem + 0.1vw);
    color: white;
    font-weight: 400;
    margin-bottom: 0.2rem;
}



.selectStyle{
    font-family: 'Merriweather', serif;
    font-weight: 500;
    font-size: calc(0.85rem + 0.06vw);
}