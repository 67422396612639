

 
 .HydroPlant3{ width: 93%;
    object-fit: cover;
    position: absolute;
    transform: rotate(-8deg);
    z-index: 1;
     }

.EWT, .LWT, .EWT1, .LWT1 , .RequiredPopup{
    position: absolute;
    z-index: 3;
}
.EWT{
    margin-top: 16%;
    margin-left: 4%;
}
.LWT{
    margin-top: 33%;
margin-left: 13%;
}
.EWT1{
    margin-top: 18%;
    margin-left: 68%;
}
.LWT1{
    margin-left: 55%;
    margin-top: 0%;
}

.RequiredPopup{
margin-left:60%;
margin-top: 31%;
}
/* images required */
.RequiredLowLift{
    height: 100px;
    width: 90px;
}

/* large Screen */

.EWT2, .LWT2, .EWT3, .LWT3 , .HydroPlant4{
    position: absolute;
    z-index: 3;
}
.HydroPlant4{
    width: 70%;
    object-fit: contain;
    position: absolute;
    z-index: 0;
    margin-left: 16%;
    margin-top: -15%;
    transform: rotate(-1deg);
}
.EWT2{
    margin-top: 1%;
    margin-left: 11%;
}
.LWT2{
    margin-top: 6%;
    margin-left: 14%;
}
.LWT3{
    margin-left: 67%;
    margin-top: -12%;
}
.EWT3{
    margin-top: -7%;
    margin-left: 70%;
}
.RequiredPopup1{
    position: absolute;
    z-index: 3;
    margin-left:83%;
    margin-top: 4%;
}
.left-arrow2{
    position: absolute;
    z-index: 3;
    margin-left:68%;
    margin-top: 5%;
    transform: rotate(8deg);
}
.RequiredLowLift1{
    height: 150px;
    width: 120px;
    object-fit: cover;
}