.Box1{
    padding-top: 10%;
    text-align: center;
    z-index: 5;
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 1%;
}

.Box2{
    text-align: center;
    position: relative;
    z-index: 0;
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 1%;
}

.Box2 .field{
    margin: 2%;
    background-color: #fff;
}


