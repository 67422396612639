.HydroPlant5{ width: 92%;
    object-fit: contain;
    position: absolute;
    transform: rotate(-8deg);
    z-index: 1;
     }

.Value1 , .Value2, .Value3,.Value4 ,.Hydrolic{
position: absolute;
z-index: 2;
}
.Features1{
    position: absolute;
    z-index: 3;
    height: auto;
    width: 100%;
    margin-top: 10px;
}
.Components1{
    position: absolute;
    z-index: 3;
    height: auto;
    width: 100%;
    margin-top: 20px;
}



.Value1{
    margin-top: 15%;
    margin-left: 5%;
}
.Value2{
margin-top: 30%;
margin-left: 14%;
}
.Value3{
    margin-top: -2%;
    margin-left: 60%;
}
.Value4{
    margin-left: 70%;
    margin-top: 18%;
}
.Hydrolic{
    height: 12%;
    margin-top: 30%;
    margin-left: 52%;
}
/* Tool Box3 large Screen */

.HydroPlant6 , .Value5 ,.Value6,.Value7 ,.Value8 , .Hydrolic1{
position: absolute;
z-index: 1;
}
.HydroPlant6{ width: 65%;
    object-fit: contain;
    margin-left: 0%;
    margin-top: -9%;
    transform: rotate(-4deg);
}

.Features{
    position: absolute;
    z-index: 4;
    width: 70%;
    margin-top: -300px;
}
.Components{
    position: absolute;
    z-index: 4;
    width: 70%;
    margin-top: -300px;
}
.Hydrolic1{
    height: 150px;
    width: 200px;
    margin-top: 20%;
}
.Value5{
  margin-top: 4%;  
  margin-left: 3%;
}
.Value6{
    margin-top: 11%;
    margin-left: 8%;
}
.Value7{
    margin-left: 49%;
    margin-top: -7%;
}

.Value8{
    margin-left: 50%;
    margin-top: 3.5%;
}
.Hydrolic1{
 
    margin-left: 60%;
    margin-top: 6%;
}